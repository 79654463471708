
body{
	
	font-family: 'Roboto', sans-serif !important;
	background-color:#fae0e0;
}

.bg-img{
	background:url(../img/bg.jpg) repeat #8e0000;
	position: fixed;
}

	

.container {
    max-width: 1200px; /* Set a maximum width for the container */
    margin: 0 auto; /* Center the container horizontally */
  }
.image{
background-image: url(../img/sHiva.jpg) !important;
background-repeat: no-repeat;
background-size: cover;
background-size: cover; /* This will scale the background image to cover the entire container */
background-position: center; /* This will center the background image within the container */
height: 100%; /* Set the height to 100% to cover the entire container vertically */
}
#btm-btn-fixed{
	width:100px;
	height:100px;
	position:fixed;
	bottom:0px;
	
	outline:none;
	border:none;
	-moz-border-radius: 50%;
	border-radius: 50%;
	left:50%;
	padding:0px;
	margin-bottom:-50px;
	margin-left:-50px;
	cursor:pointer;
	z-index:1040;
	    -webkit-transition: all 0.3s;
    -moz-transition: all 0.3s;
    -o-transition: all 0.3s;
    transition: all 0.3s;
}

#btm-btn-fixed i{
	font-size:30px;
	top:-20px;
	position:relative;
	text-shadow: 0px 3px 3px rgba(0, 0, 0, 0.3); 
	    -webkit-transition: all 0.3s;
    -moz-transition: all 0.3s;
    -o-transition: all 0.3s;
    transition: all 0.3s;
}

.home-container{
	width:100%;
	height: 100vh;
	background:url(../img/bg-flower.png) top no-repeat;
	
}

.orange-btn{
	background-color:#ff6600;
	color:#ffe0cc;
}

.orange-btn {
  box-shadow: 0 0 0 0 rgba(255, 102, 0, 0.5);
  border-radius: 50%;
  -webkit-animation: pulseo 1.25s infinite cubic-bezier(0.66, 0, 0, 1);
  -moz-animation: pulseo 1.25s infinite cubic-bezier(0.66, 0, 0, 1);
  -ms-animation: pulseo 1.25s infinite cubic-bezier(0.66, 0, 0, 1);
  animation: pulseo 1.25s infinite cubic-bezier(0.66, 0, 0, 1);
  
}

@-webkit-keyframes pulseo {to {box-shadow: 0 0 0 20px rgba(102, 189, 81, 0);}}
@-moz-keyframes pulseo {to {box-shadow: 0 0 0 20px rgba(102, 189, 81, 0);}}
@-ms-keyframes pulseo {to {box-shadow: 0 0 0 20px rgba(102, 189, 81, 0);}}
@keyframes pulseo {to {box-shadow: 0 0 0 20px rgba(102, 189, 81, 0);}}

.white-btn {
  box-shadow: 0 0 0 0 rgba(255, 255, 255, 0.5);
  border-radius: 50%;
  -webkit-animation: pulsew 1.25s infinite cubic-bezier(0.66, 0, 0, 1);
  -moz-animation: pulsew 1.25s infinite cubic-bezier(0.66, 0, 0, 1);
  -ms-animation: pulsew 1.25s infinite cubic-bezier(0.66, 0, 0, 1);
  animation: pulsew 1.25s infinite cubic-bezier(0.66, 0, 0, 1);
  background-color:#ffe0cc;
  color:#ff6600;
}

@-webkit-keyframes pulsew {to {box-shadow: 0 0 0 20px rgba(102, 189, 81, 0);}}
@-moz-keyframes pulsew {to {box-shadow: 0 0 0 20px rgba(102, 189, 81, 0);}}
@-ms-keyframes pulsew {to {box-shadow: 0 0 0 20px rgba(102, 189, 81, 0);}}
@keyframes pulsew {to {box-shadow: 0 0 0 20px rgba(102, 189, 81, 0);}}

.white-btn i{
	 transform: rotate(180deg);
}

.footerbtm-circle{
	width:800px;
	height:800px;
	position:fixed;
	background-color: rgba(255, 102, 0, 0.75);
	bottom:0px;
	-moz-border-radius: 50%;
	border-radius: 50%;
	left:50%;
	margin-left:-400px;
	margin-bottom:-630px;
	z-index:1005 !important;
	-webkit-transition: all 0.3s;
    -moz-transition: all 0.3s;
    -o-transition: all 0.3s;
    transition: all 0.3s;
}

.footerbtn{
	width:100%;
	border:none;
	outline:none !important;
	cursor:pointer;
	position:relative;
	background:none;
	padding:0px;
	margin:0px;
	z-index:1038 !important;
}

.footerbtn span{
	width:70px;
	height:70px;
	background-color:#ffe0cc;
	-moz-border-radius: 50%;
	border-radius: 50%;
	border:none;
	outline:none !important;
	cursor:pointer;
	left:50%;
	margin-left:-35px;
	position:relative;
	
	float:left;
	overflow:hidden;
	
}

.footerbtn span img{
	width:40px;
	height:40px;
	object-fit:cover;
	position: relative;
	top: 10px;

}

.footerbtn h6{
	width:100%;
	text-align:center;
	padding:0px;
	margin:5px 0px 0px 0px;
	color:#ffe0cc;
	float:left;
	font-weight:500;
	font-size:13px;
}

.fixed-bottom .col-lg-5{
	padding-left:30px;
	padding-right:30px;
}

.fixed-bottom .col-3:nth-child(1) .footerbtn,
.fixed-bottom .col-3:nth-child(4) .footerbtn{
	bottom:25px;
} 

.fixed-bottom .col-3:nth-child(2) .footerbtn,
.fixed-bottom .col-3:nth-child(3) .footerbtn{
	bottom:55px;
} 

.fixed-bottom .col-3{
	padding-left:0px;
	padding-right:0px;
}

.sopanam-logo{
	width:70%;
	margin-left:15%;
	margin-top:32%;
	opacity: 0.5;
}

.bookseva-icon{
	width:50%;
	margin-left:25%;
	margin-top:10%;
	opacity: 0.3;
}

.footer-circle-hide{
	margin-bottom:-830px;
}

.footer-circle-view{
	margin-bottom:-620px;
}

#footerbtns-con{
	-webkit-transition: all 0.3s;
    -moz-transition: all 0.3s;
    -o-transition: all 0.3s;
    transition: all 0.3s;
	position:relative;
}

.hide-foot-btns{
	position:relative;
	bottom:-160px;
}

.view-foot-btns{
	position:relative;
	bottom:100px;
}

.view-foot-btns .col-3:nth-child(1) .footerbtn{
	opacity:1;
	-webkit-animation:linear;
	-webkit-animation-name: btnopacityone;
	-webkit-animation-duration: 0.6s;
}

.view-foot-btns .col-3:nth-child(2) .footerbtn{
	opacity:1;
	-webkit-animation:linear;
	-webkit-animation-name: btnopacitytwo;
	-webkit-animation-duration: 0.7s;
}

.view-foot-btns .col-3:nth-child(3) .footerbtn{
	opacity:1;
	-webkit-animation:linear;
	-webkit-animation-name: btnopacitythree;
	-webkit-animation-duration: 0.8s;
}

.view-foot-btns .col-3:nth-child(4) .footerbtn{
	opacity:1;
	-webkit-animation:linear;
	-webkit-animation-name: btnopacityfour;
	-webkit-animation-duration: 0.9s;
}

@-webkit-keyframes btnopacityone {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@-webkit-keyframes btnopacitytwo {
  0% {
    opacity: 0;
  }
  
  10% {
    opacity: 0;
  }
  
  100% {
    opacity: 1;
  }
}

@-webkit-keyframes btnopacitythree {
  0% {
    opacity: 0;
  }
  
  20% {
    opacity: 0;
  }
  
  100% {
    opacity: 1;
  }
}

@-webkit-keyframes btnopacityfour {
  0% {
    opacity: 0;
  }
  
  30% {
    opacity: 0;
  }
  
  100% {
    opacity: 1;
  }
}


.header-wrapper{
	width:100%;
	background:url(../img/bg.jpg) repeat #8e0000;
	color:#ffefe2;
	padding:50px 0px 0px 0px;
	text-align:center;
	
}

.header-wrapper .container img{
	margin:15px 10px 15px 0px;
}

.sopanam-logo{
	width:260px;
}

.header-wrapper h4{
	width:100%;
	text-transform:uppercase;
	padding:12px 0px;
	margin:0px;
	text-align:center;
	background: rgba(0, 0, 0, 0.25);
	font-weight:bold;
	letter-spacing:0.5px;
	text-shadow: 2px 2px 4px rgba(0,0,0,0.25);
}

.menu-container{
	position:relative;
	z-index:999;
	margin-bottom:15px;
}

.menu-container input{
	border:none;
	outline:none;
	width:100%;
	background:none;
	padding-left:15px;
	color:#fbdece;
	min-height:45px;
	border:2px solid #f1c152;
	-moz-border-radius: 25px;
	border-radius: 25px;
}

.menu-container input::-webkit-input-placeholder { /* Chrome/Opera/Safari */
  color: #f1c152;
}
.menu-container input::-moz-placeholder { /* Firefox 19+ */
  color: #f1c152;
}
.menu-container input:-ms-input-placeholder { /* IE 10+ */
  color: #f1c152;
}
.menu-container input:-moz-placeholder { /* Firefox 18- */
  color: #f1c152;
}

.menu-container button{
	position:absolute;
	right:0px;
	top:0px;
	z-index:990;
	border:none;
	outline:none;
	background:none;
	padding:0px 20px;
	color:#f1c152;
	min-height:45px;
	font-size:22px;
	cursor:pointer;
}


.footer-btmwrapper{
	text-align:center;
	background:url(../img/bg.jpg) repeat #8e0000;
	min-height:40px;
	-webkit-box-shadow: 0px 0px 10px 0px rgba(0,0,0,0.4);
	-moz-box-shadow: 0px 0px 10px 0px rgba(0,0,0,0.4);
	box-shadow: 0px 0px 10px 0px rgba(0,0,0,0.4);
	position:fixed;
	top:0px;
	width:100%;
	z-index:1080;

}

.footer-btmwrapper button{
	width:100%;
	text-align:center;
	padding:0px 0px;
	margin:0px;
	cursor:pointer;
	text-decoration:none;
	color:#fff;
	text-transform:uppercase;
	display:block;
	background:none;
	border:none;
	border-right:1px solid #6c0000;
	outline:none;
	min-height:40px;
	font-weight:700;
	font-size:16px;
}

.footer-btmwrapper button i{
	margin:8px 0px 0px 0px;
}

.footer-btmwrapper button img{
	height:20px;
	margin:0px 10px 0px 0px;
}

.footer-btmwrapper button span{
	margin:0px 0px 0px 0px;
	position:relative;
	top:1px;
}

.footer-btmwrapper button:last-child{
	border-right:none;
}

.footer-btmwrapper button font{
	width:15px;
	height:15px;
	background-color:#f1c361;
	position:absolute;
	top:5px;
	margin-left:10px;
	float:left;
	color:#dd2027;
	font-weight:700;
	font-size:10px;
	-moz-border-radius: 50%;
	border-radius: 50%;
}

.footer-btmwrapper .col-3,
.footer-btmwrapper .row,
.footer-btmwrapper .col-lg-4{
	padding:0px;
	margin:0px;
}

.footer-btmwrapper .container{
	padding-left:0px;
	padding-right:0px;
}

.page-logos{
	width:180px;
	margin:10px 0px !important;
}

.offeringlist{
	width: 100%;
    background-color: #faefef !important;
    padding: 10px;
    position: relative;
    -moz-border-radius: 5px;
    border-radius: 5px;
    -webkit-box-shadow: 1px 2px 2px 0px rgba(0,0,0,0.1);
    -moz-box-shadow: 1px 2px 2px 0px rgba(0,0,0,0.1);
    box-shadow: 1px 2px 2px 0px rgba(0,0,0,0.1);
    float: left;
    text-decoration: none;
    color: #000000;
    margin: 6px 0px;
}


.offeringlist h5{
	padding:0px;
	margin:0px;
	width:100%;
	float:left;
	font-weight:bold;
}

.offeringlist h6{
	padding:4px 20px 2px 20px;
	margin:5px 0px 5px 0px;
	font-size:14px;
	border:none;
	background-color:#f1c152;
	float:left;
	color:#513a05;
	font-weight:bold;
	-moz-border-radius: 15px;
	border-radius: 15px;
}

.offeringlist p{
	padding:0px;
	margin:8px 0px 8px 0px;
	line-height:20px;
	width:100%;
	float:left;
}

.offeringlist h4{
	padding:0px;
	margin:0px;
	font-weight:bold;
	font-size:16px;
	width:100%;
	float:left;
}

.offeringlist h4 img{
	height:11px;
	position:relative;
	top:-1px;
	margin-right:0px;
}

.offeringlist .col-4 .addbtn{
	width:100%;
	padding:8px 0px;
	margin:0px;
	border:none;
	font-weight:700;
	background-color:#dd2027;
	color:#f3dbac;
	outline:none;
	cursor:pointer;
	font-size:15px;
	border-bottom-left-radius: 5px;
	border-bottom-right-radius: 5px;
	position:relative;
}

.fixed-bottom{
	margin-bottom:-100px !important;
}

.pagecontainer{
	margin-top:15px;
	margin-bottom:15px;
}

.offeringlist .col-4 .addbtn:hover{
	background-color:#f1c152;
	color:#000;
}

.offeringlist .col-4 .addbtn i{
	margin-left:5px;
}

.offeringlist .col-4 span{
	width:100%;
	height:100px;
	float:left;
}

.offeringlist .col-4 span img{
	width:100%;
	height:100px;
	object-fit:cover;
}

.left-arrow{
	font-size:24px !important;
}

.left-arrow i{
	margin-top:4px !important;
}


.menu-contain{
	position:fixed;
	width:100%;
	background: rgba(204, 32, 33, 0.95);
	z-index:1200;
	height:100vh;
	right:0%;
	overflow:hidden;
	top:0px;
}

.menu-contain h6{
	padding:0px;
	margin:20px 0px 0px 0px;
	font-size:14px;
	color:#FFFFFF;
	opacity:0.75;
}

.menu-contain h4{
	width:100%;
	text-align:center;
	float:left;
	text-transform:uppercase;
	padding:10px 0px;
	margin:0px;
	font-weight:bold;
	background-color:#f1c25a;
	color:#dc1d25;
	font-size:20px;
	text-shadow: 1px 1px 1px rgba(0,0,0,0.2);
}

.menu-contain .close-btn{
	position:relative;
	float:right;
	margin:10px 10px 0px 0px;
	border:none;
	outline:none;
	background:none;
	padding:0px;
	font-size:28px;
	color:#FFFFFF;
    -webkit-transition: all 0.4s;
    -moz-transition: all 0.4s;
    -o-transition: all 0.4s;
    transition: all 0.4s;
	z-index:998;
}

/****accordian style***/

.menu-contain .innerdiv{
	width:90%;
	margin-left:5%;
}

.menu-contain .card{
	background:none;
	border:none;
	width:100%;
	margin:2px 0px;
}

.menu-contain .btn-link-main{
	font-weight: bold;
    background-color: #f1c25a !important;
    color: #dc1d25 !important;
    font-size: 20px;
    text-shadow: 1px 1px 1px rgba(0,0,0,0.2);
	outline:none  !important;
	box-shadow: 0 0 0 0.2rem rgba(0,123,255,0)  !important;
}

/****accordian style end***/

.menu-contain div button{
	width:100%;
	float:left;
	padding:8px 0px;
	margin:0px;
	border:none;
	outline:none;
	background:none;
	text-transform:uppercase;
	font-weight:700;
	color:#FFFFFF;
	border-bottom:1px solid #f9676c;
}

.menu-contain div button:hover{
	background-color:#bd0a10;
}

.menu-contain div button:last-child{
	border-bottom:none;
}

.menu-right-hide{
	right:-100%;
}

.menu-left-hide{
	left:-100%;
}

.developedby{
	position:absolute;
	left:20px;
	bottom:55px;
	opacity:0.75;
}

.menu-right{
	right:-100%;
	-webkit-animation-name: menuright-close;
	-webkit-animation-duration: 0.3s;
	animation-timing-function: ease-out;
}

@-webkit-keyframes menuright-close{
    0% {
		right:0%;
	}

    100%{
		right:-100%;
	}
}

.menu-left{
	right:-100%;
	-webkit-animation-name: menuleft-close;
	-webkit-animation-duration: 0.3s;
	animation-timing-function: ease-out;
}

@-webkit-keyframes menuleft-close{
    0% {
		left:0%;
	}

    100%{
		left:-100%;
	}
}

.menu-right-open{
	right:0%;
	-webkit-animation-name: menuright-open;
	-webkit-animation-duration: 0.3s;
	animation-timing-function: ease-out;
}

@-webkit-keyframes menuright-open{
    0% {
		right:-100%;
	}

    100%{
		right:00%;
	}
}

.menu-left-open{
	left:0%;
	-webkit-animation-name: menuleft-open;
	-webkit-animation-duration: 0.3s;
	animation-timing-function: ease-out;
}

@-webkit-keyframes menuleft-open{
    0% {
		left:-100%;
	}

    100%{
		left:00%;
	}
}

.menu-contain .privacybtn{
	width:100%;
	padding:0px 0px; 
	margin:0px;
	position:absolute;
	bottom:0px;
	background-color: rgba(0, 0, 0, 0.45);
	font-size:15px;
	float:left;
}

.menu-contain  .privacybtn a{
	width:50%;
	text-align:center;
	padding:5px 0px; 
	line-height:30px;
	display:block;
	float:left;
	text-decoration:none;
	color:#FFFFFF;
}

.menu-contain  .privacybtn a:last-child{
	background-color: rgba(0, 0, 0, 0.15);
}

#parent .privacybtn{
	position:absolute;
	bottom:0px;
	width:100%;
	text-align:center;
	left:0px;
}

#parent .privacybtn a{
	padding:0px 20px;
}


.dietylist {
    width: 100%;
    background-color: #faefef;
    padding: 10px;
    position: relative;
    -moz-border-radius: 5px;
    border-radius: 5px;
    -webkit-box-shadow: 1px 2px 2px 0px rgba(0,0,0,0.1);
    -moz-box-shadow: 1px 2px 2px 0px rgba(0,0,0,0.1);
    box-shadow: 1px 2px 2px 0px rgba(0,0,0,0.1);
    float: left;
    text-decoration: none;
    color: #000000;
	min-height:248px;
    margin: 6px 0px;
}

.dietylist img{
	width:100%;
	height:140px;
	object-fit:cover;
}

.diety-container .col-6:nth-child(even){
	padding-left:7px !important;
}	

.diety-container .col-6:nth-child(odd){
	padding-right:7px !important;
}	

.diety-container .col-6 button{
	width:100%;
	padding:6px 0px;
	margin:6px 0px 0px 0px;
	border:none;
	font-size:15px;
	font-weight:bold;
	background-color:#dc1d25;
	color:#f1c25a;
}

.diety-container .col-6 h5{
	width:100%;
	text-align:center;
	font-size:15px;
	padding:0px;
	margin:6px 0px 0px 0px;
	font-weight:bold;
}

.pushpanjalilist{
	width: 100%;
    background-color: #faefef;
    padding: 4px 10px 4px 40px;
    position: relative;
    -moz-border-radius: 5px;
    border-radius: 5px;
    -webkit-box-shadow: 1px 2px 2px 0px rgba(0,0,0,0.1);
    -moz-box-shadow: 1px 2px 2px 0px rgba(0,0,0,0.1);
    box-shadow: 1px 2px 2px 0px rgba(0,0,0,0.1);
    float: left;
    text-decoration: none;
    color: #000000;
    margin: 6px 0px;
	outline:none !important;
	text-align:left;
	cursor:pointer;
	border:none;
}

.pushpanjalilist i{
	position:absolute;
	left:10px;
	top:20px;
	color:#dc1d25;
}

.pushpanjalilist h6{
	padding:0px;
	margin:5px 0px;
	font-size:15px;
}

.pushpanjalilist span{
	position:absolute;
	right:15px;
	top:17px;
	text-align:right;
	font-weight:bold;
	font-size:16px;
}

.pushpanjalilist span img{
	height:12px;
	position:relative;
	top:-2px;
	margin-right:2px;
}


.sevalist{
	width: 100%;
    background-color: #faefef;
    padding: 10px;
    position: relative;
    -moz-border-radius: 5px;
    border-radius: 5px;
    -webkit-box-shadow: 1px 2px 2px 0px rgba(0,0,0,0.1);
    -moz-box-shadow: 1px 2px 2px 0px rgba(0,0,0,0.1);
    box-shadow: 1px 2px 2px 0px rgba(0,0,0,0.1);
    float: left;
    text-decoration: none;
    color: #000000;
    margin: 6px 0px;
	text-align:center;
}

.sevalist button{
	width:100%;
	padding:6px 0px;
	margin:6px 0px 0px 0px;
	border:none;
	font-size:15px;
	font-weight:bold;
	background-color:#cc2021;
	color:#f1c25a;
}

.sevalist h6{
	padding: 4px 20px 2px 20px;
    margin: 5px 0px 10px 0px;
    font-size: 14px;
    border: none;
    background-color: #f1c152;
    color: #513a05;
	width:auto;
    font-weight: bold;
    -moz-border-radius: 15px;
	display:block;
    border-radius: 15px;
}

.sevalist h5{
	padding:0px;
	margin:6px 0px;
	font-size:16px;
	width:100%;
	text-align:center;
}

/*responsive table style start*/

.table-condensed tr td{
	font-size:15px;
	padding:8px 10px;
}

.table-bordered{
	/*background-color:#00325c;*/
	border:none;
	padding:0px;
}

#no-more-tables{
	color:#333333;
	border:none;
}

.table-bordered td, .table-bordered th{
	border:none;
}

#no-more-tables tbody tr:nth-of-type(odd) {
	background-color: #ecc6c6;
}

.table-header{
	text-transform:uppercase;
	background-color: #cc2021;
	color:#f1c152;
	font-size:14px;
}

#no-more-tables thead td, 
#no-more-tables thead th{
	padding:14px 10px;
	font-weight:700;
}


@media only screen and (max-width: 800px) {
    
	#no-more-tables table, 
	#no-more-tables thead, 
	#no-more-tables tbody, 
	#no-more-tables th, 
	#no-more-tables td, 
	#no-more-tables tr { 
		display: block; 
	}
 
	#no-more-tables thead tr { 
		position: absolute;
		top: -9999px;
		left: -9999px;
	}
 
	#no-more-tables tr { 
		padding:12px;
	}
 
	#no-more-tables td { 
		border: none;
		border-bottom: 1px solid #d89e9e; 
		position: relative;
		padding-left: 50%; 
		white-space: normal;
		text-align:left;
		color:#333333;
		min-height:38px;
	}
	
 
	#no-more-tables td:before { 
		position: absolute;
		top: 6px;
		left: 6px;
		width: 45%; 
		padding-right: 10px; 
		white-space: nowrap;
		text-align:left;
		font-weight: bold;
	}
 
	#no-more-tables td:before { content: attr(data-title); }
	
	#no-more-tables tbody tr:nth-of-type(odd) {
		background-color: #ecc6c6;
	}
}

/*responsive table style end*/

.tablepage-con{
	margin-top:30px;
	margin-bottom:20px;
}

.page-controls{
	margin-bottom:20px;
}

.page-controls input,
.page-controls select{
	background:none !important;
	border:2px solid #651819;
	height:45px;
	-moz-border-radius: 24px;
	border-radius: 24px;
}

.page-controls .col-md-4 span{
	width:100%;
	position:relative;
	float:left
}

.page-controls .col-md-4 span i{
	position:absolute;
	right:15px;
	left:auto;
	top:12px;
	font-size:20px;
	color:#651819;
}

.page-controls input:focus,
.page-controls select:focus {
  border-color: #dc1d25;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0), 0 0 8px rgba(255, 0, 0, 0);
}


.page-controls .col-md-4 button{
	width:100%;
	text-transform:uppercase;
	border:none;
	height:45px;
	-moz-border-radius: 24px;
	border-radius: 24px;
	font-size:15px;
	font-weight:bold;
	outline:none;
	cursor:pointer;
}

.page-controls .col-lg-4 .col-md-4:nth-child(1){
	padding-right:0px;
}

.page-controls .col-lg-4 .col-md-4:nth-child(2){
	padding-left:5px;
	padding-right:5px;
}

.page-controls .col-lg-4 .col-md-4:nth-child(3){
	padding-left:0px;
}

.btn-show{
	background-color:#f46b03;
	color:#FFFFFF;
}

.btn-show:hover{
	background-color:#ce5706;
}

.btn-print{
	background-color:#db4fa5;
	color:#FFFFFF;
}

.btn-print:hover{
	background-color:#c4328b;
}

.btn-export{
	background-color:#cc2021;
	color:#FFFFFF;
}

.btn-export:hover{
	background-color:#8f1819;
}

.offeringlist{
	width: 100%;
    background-color: #fdf5d7;
    padding: 10px;
    position: relative;
    -moz-border-radius: 5px;
    border-radius: 5px;
    -webkit-box-shadow: 1px 2px 2px 0px rgba(0,0,0,0.1);
    -moz-box-shadow: 1px 2px 2px 0px rgba(0,0,0,0.1);
    box-shadow: 1px 2px 2px 0px rgba(0,0,0,0.1);
    float: left;
    text-decoration: none;
    color: #000000;
    margin: 6px 0px;
}

.offeringlist h5{
	padding:0px;
	margin:0px;
	width:100%;
	float:left;
	font-weight:bold;
}

.offeringlist h6{
	padding:4px 20px 2px 20px;
	margin:5px 0px 5px 0px;
	font-size:14px;
	border:none;
	background-color:#f1c152;
	float:left;
	color:#513a05;
	font-weight:bold;
	-moz-border-radius: 15px;
	border-radius: 15px;
}

.offeringlist p{
	padding:0px;
	margin:8px 0px 8px 0px;
	line-height:20px;
	width:100%;
	float:left;
}

.offeringlist h4{
	padding:0px;
	margin:0px;
	font-weight:bold;
	font-size:16px;
	width:100%;
	float:left;
}

.offeringlist h4 img{
	height:11px;
	position:relative;
	top:-1px;
	margin-right:0px;
}

.offeringlist .col-4 .addbtn{
	width:100%;
	padding:8px 0px;
	margin:0px;
	border:none;
	font-weight:700;
	background-color:#dd2027;
	color:#f3dbac;
	outline:none;
	cursor:pointer;
	font-size:15px;
	border-bottom-left-radius: 5px;
	border-bottom-right-radius: 5px;
}

.offeringlist .col-4 .addbtn:hover{
	background-color:#3c1f35;
	color:#f1c25a;
}

.offeringlist .col-4 .addbtn i{
	margin-left:5px;
}

.offeringlist .col-4 span{
	width:100%;
	height:100px;
	float:left;
}

.offeringlist .col-4 span img{
	width:100%;
	height:100px;
	object-fit:cover;
}

.proceedcon h5{
	padding:0px;
	margin:0px;
	font-size:16px;
}

.proceedcon h5{
	padding:8px 10px;
}

.proceedcon h5:nth-child(2){
	background-color:#eed8d8;
}	

.proceedcon h5:last-child{
	
}

.proceedcon h5 img{
	height:12px;
	position:relative;
	top:-2px;
}

.proceedcon h5 font{
	float:right;
}

.proceedcon .proceedbtn{
	width: 100%;
    padding: 10px 0px;
    text-transform: uppercase;
    font-weight: 700;
    font-size: 16px;
	border:none;
	outline:none;
	margin-top:15px;
    background-color: #cc2021;
	color:#fff !important;
	cursor:pointer;
}

.checkoutlist .closebtns{
	position:absolute;
	right:10px;
	top:-5px;
	background:none;
	border:none;
	outline:none;
}

.checkoutlist h4{
	text-align:right;
	margin-top:5px;
}

.checkoutlist h6{
	margin-bottom:10px;
}

.checkoutlist input{
	width:50%;
	float:right;
	height:36px;
	margin-bottom:0px;
	border:2px solid #7f1819 !important;
	box-shadow:none !important;
	outline:none;
	background:#faefef !important;
}

.checkoutlist label{
	font-weight:700;
	margin-top:5px;
}

.calandar-list{
	width:100%;
	border:2px solid #661819;
	padding:10px 20px;
	margin-top:-2px;
	background:none;
	cursor:pointer;
	outline:none !important;
	text-align:left
}

.calandar-list:hover{
	background-color:#eac8c8;
}

.calandar-list .col-6:last-child h4{
	padding:0px;
	margin:-5px 0px 0px 0px;
	text-align:right;
	font-weight:900;
	float:right;
	font-size:36px;
}

.calandar-list .col-6:last-child h4 font{
	width:100%;
	float:right;
	font-size:16px;
	margin-top:-4px;
}

.calandar-list .col-6:last-child h6{
	width:100%;
	float:right;
	text-align:right;
	font-weight:bold;
	padding:0px;
	margin:0px;
	font-size:15px;
	color:#dc1d25;
}

.calandar-list .col-6:first-child h6:nth-child(2){
	padding:0px;
	margin:0px;
	font-weight:bold;
	font-size:15px;
}

.calandar-list .col-6:first-child h6:nth-child(1){
	padding:0px;
	margin:0px 0px 10px 0px;
	font-size:18px;
	font-weight:900;
	width:100%;
	float:left;
	color:#dc1d25;
}

.calandar-list .col-6:first-child h6:nth-child(1) font{
	width:100%;
	float:left;
	font-size:15px;
	color:#333333;
}

.login-wrapper{
	width:100%;
	background:url(../img/bg.jpg) repeat #8e0000;
	min-height:100vh;
	position:relative;
}

#parent {
        display: table; 
        width: 100%; 
        text-align: center; 
		min-height:100vh;
}

#child {
        display: table-cell;
        vertical-align: middle;
}

.login-wrapper label{
	font-weight:bold;
	padding:0px;
	margin:0px 0px 5px 0px;
	font-size:15px;
	color:#fae0e0;
}

.login-wrapper input{
	width:100%;
	border:none;
	text-align:center;
	outline:none;
	height:45px;
	-moz-border-radius: 25px;
	border-radius: 25px;
	background-color: rgba(0, 0, 0, 0.5);
	color:#FFFFFF;
	padding-left:50px;
	font-size:16px;
}

.login-wrapper .col-lg-5 button{
	width:100%;
	border:none;
	height:45px;
	font-weight:900;
	-moz-border-radius: 25px;
	border-radius: 25px;
	color:#513a05;
	cursor:pointer;
	outline:none;
	background-color:#f1c152;
}

.login-wrapper h6{
	padding:0px;
	margin:25px 0px 15px 0px;
	color:#fae0e0;
	font-size:15px;
}

.login-wrapper h6 a{
	color:#fae0e0;
	text-decoration:none;
	font-weight:900;
}

.forgotpass{
	color:#fae0e0;
	text-decoration:none;
}

.forgotpass:hover,
.login-wrapper h6 a:hover{
	color:#fff;
	text-decoration:none;
}

.login-wrapper .form-group{
	position:relative;
}

.login-wrapper .form-group span{
	width:50px;
	height:35px;
	position:absolute;
	left:0px;
	bottom:5px;
	border-top-left-radius: 25px;
	border-bottom-left-radius: 25px;
	border-right:1px solid #feedb2;;
	padding:5px 0px 0px 3px;
}

.login-wrapper .form-group span i{
	color:#feedb2;
}

.login-wrapper input::-webkit-input-placeholder { 
  color:#feedb2;
}

.login-wrapper input::-moz-placeholder {
  color:#feedb2;
}

.login-wrapper input:-ms-input-placeholder { 
 color:#feedb2;
}

.login-wrapper input:-moz-placeholder {
  color:#feedb2;
}

.login-wrapper font img{
	margin-bottom:25px;
}

.page-btn{
	background:none;
	border:none;
	outline:none !important;
	position:absolute;
	top:20px;
	color:#f1c152;
	font-size:24px;
	left:8px;
	z-index:999;
	cursor:pointer;
	text-shadow: 2px 2px 2px rgba(0,0,0,0.2);
}

.closebtn{
	left:auto;
	right:8px;
}

.login-logo{
	width:60%;
}

/* form page */

.form-wrapper .form-group input[type=text], .form-wrapper .form-group select{
	background: none !important;
    border: 2px solid #360000;
    height: 45px;
    -moz-border-radius: 24px;
    border-radius: 24px;
    color: #000000;
}

.form-wrapper .form-group textarea{
	background: none !important;
    border: 2px solid #360000;
    height: 100px;
    -moz-border-radius: 24px;
    border-radius: 24px;
    color: #000000;
}

.form-wrapper .form-group input:focus,
.form-wrapper .form-group select:focus,
.form-wrapper .form-group textarea:focus {
  border-color: #950000;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0), 0 0 8px rgba(101, 190, 82, 0);
}

button{
	box-shadow:none !important;
}

.form-wrapper .form-group label{
	font-size: 15px;
    padding: 0px;
    margin: 0px 0px 2px 0px;
    font-weight: 500;
    color: #360000;
}

.form-wrapper .btn{
	width:100%;
	margin-bottom:15px;
	background:none;
	background: none;
    height: 45px;
    -moz-border-radius: 24px;
    border-radius: 24px;
	text-transform:uppercase;
	font-weight:bold;
	color:#FFFFFF;
}

.green-btn{
	background-color:#65be52 !important;
}

.green-btn:hover{
	background-color:#53ac40 !important;
}

.purple-btn{
	background-color:#8f44ad !important;
}

.purple-btn:hover{
	background-color:#7c309b !important;
}

.yellow-btn{
	background-color:#f19d16 !important;
}

.yellow-btn:hover{
	background-color:#d98909 !important;
}

.red-btn{
	background-color:#940000 !important;
}

.red-btn:hover{
	background-color:#6c0000 !important;
}

.orange-btns{
	background-color:#fe660a !important;
}

.orange-btns:hover{
	background-color:#d95404 !important;
}

.blue-btn{
	background-color:#014d8f !important;
}

.blue-btn:hover{
	background-color:#012b4f !important;
}

/*  checkbox-style */

.form-row .col-12, .form-row .col-11, .form-row .col-10, .form-row .col-9, .form-row .col-8, .form-row .col-7, .form-row .col-6, .form-row .col-5, .form-row .col-4, .form-row .col-3, .form-row .col-2, .form-row .col-1{
	margin-top:5px;
	margin-bottom:5px;
	 color: #360000;
}

.form-row input[type='radio'],
.form-row input[type='checkbox']{
	margin-right:15px;
	outline:none !important;
	background:none;
}

.form-row input[type='radio']:after {
        width: 18px;
        height: 18px;
        border-radius: 15px;
        top: -2px;
        left: -2px;
        position: relative;
        background-color: #fae0e0;
        content: '';
        display: inline-block;
        visibility: visible;
        border: 2px solid #360000;
}

.form-row input[type='radio']:checked:after {
        width: 18px;
        height: 18px;
        border-radius: 15px;
        top: -2px;
        left: -2px;
        position: relative;
        background-color: #950000;
        content: '';
        display: inline-block;
        visibility: visible;
        border: 2px solid #950000;
}

.form-row input[type='checkbox']:after {
        width: 18px;
        height: 18px;
        border-radius: 3px;
        top: -2px;
        left: -2px;
        position: relative;
        background-color: #fae0e0;
        content: '';
        display: inline-block;
        visibility: visible;
        border: 2px solid #360000;
}

.form-row input[type='checkbox']:checked:after {
        width: 18px;
        height: 18px;
        border-radius: 3px;
        top: -2px;
        left: -2px;
        position: relative;
        background-color: #950000;
        content: '';
        display: inline-block;
        visibility: visible;
        border: 2px solid #950000;
}


/*  checkbox-style end */










@media screen and (min-width: 1024px) {
	.imgg{
		height: 120% !important ;
		width: 50% !important;
		position: absolute;
		left: 0px;
		top: 130px !important;
		
		z-index: 1;


		
	
		
	  }

	
}

@media screen and (max-width: 980px) {
	.imgg{
		height: 50% ;
		width: 100% !important;
		position: absolute;
		left: 0px;
		top: 100px;
		
		z-index: 1;


		
	
		
	  }
	
	
	
}

@media screen and (min-width: 650px) {
	.falling-pellet23{
		position: absolute;
		/* right: 0; */
		top: 0px !important;
		left: -5px !important;
		height: 100vh!important;
		width:100% !important;
			
				
			 
			}
			.card{
				/* background-color: #fe660a; */
				background-color: #FFE0CC;
				
				border-radius: 50%;
				opacity: 0.5px;
				flex-direction: column;
				justify-content: center;
				align-items: center;
				overflow: hidden; 
				height: 150px;
				
			}



	.waterr{
		position: absolute;
top: 0;
left: 150px !important;
width: 50% !important;


height: 100vh !important;
object-fit: cover;
opacity: 0.8;
z-index: 1;
	}
	.thali{
		position: absolute;
		top: -400px;
		left: 450px !important;
		width: 100px;
height: 100px;
border-radius: 50%;
margin: auto;
animation: GFG 5s infinite linear;

	}
	.thali{
		width: 100px;
		height: 100px;
		border-radius: 50%;
		margin: auto;
		animation: GFG 5s infinite linear;
		position: absolute;
		
	
		top: -400px;
		left: 220px !important;
		justify-content: center;
		align-items: center;
	}	

	.imgg{
		height: 130% !important;
		width: 100% !important;
		position: absolute;
		left: 0px;
		top: 10px;
		
		z-index: 1;


		
	
		
	  }

	.falling-pellet{
		position: absolute;
		top: 20px;
		
		position: fixed;
		/* width: 100%;			 */
		height: 500px;
		
	 
	}
	  .header-slide-con{
		width:100%;
		background-color:#ff6600;
		position:fixed;
		
		left:0px;
		position: absolute;
		top: -250px !important;;
		z-index:990;
		text-align:center;
		min-height:45px;
		padding-top:8px;
		margin-top:-40px;
			-webkit-transition: all 0.3s;
		-moz-transition: all 0.3s;
		-o-transition: all 0.3s;
		transition: all 0.3s;
		height: 19rem !important;
		/* opacity: 0.8; */
	}
	
	  .bg-img{
		width: 100%;
		position: absolute;
		position: fixed;
	

	  }
	  
	  .gim{
		position:absolute;
		top: -100px !important;
	  }
	  

.viewtopnav{
margin-top:250px !important ;
}
	
	
}

@media screen and (max-width: 480px) {
	.vishnu{
	
		height: 100vh !important;
		width: 100%;
		object-fit: cover;
		/* justify-content: center; */
		/* position: absolute;
		right: 50px;
	 */
		
	  

}
	
	.page-controls .col-lg-4 .col-4:nth-child(1){
		padding-right:0px !important;
	}
	
	.page-controls .col-lg-4 .col-4:nth-child(2){
		padding-left:5px !important;
		padding-right:5px !important;
	}
	
	.page-controls .col-lg-4 .col-4:nth-child(3){
		padding-left:0px !important;
	}
	
	.page-controls select,
	.page-controls input{
		margin-bottom:10px;
	}
	
	.page-controls .col-lg-8 .col-6:nth-child(3){
		padding-left:5px !important;
	}
	
	.page-controls .col-lg-8 .col-6:nth-child(2){
		padding-right:5px !important;
	}
	
	
}

@media screen and (max-width: 320px) {
	
}

@media screen and (max-width: 240px) {
	
}

.god{
	position: absolute;
	position: fixed;
	top: 0px;
	left: 0px;
	height: 100vh;
	width: 100%;
}


.thali{
	width: 100px;
	height: 100px;
	border-radius: 50%;
	margin: auto;
	animation: GFG 5s infinite linear;
	position: absolute;
	

	top: -400px;
	left: 150px;
	justify-content: center;
	align-items: center;
	}


	@media screen and (min-width:1000px) {
		.thali{
			position: absolute;
			top: -500px !important;
			left: 500px !important;
			width: 100px;
	height: 100px;
	border-radius: 50%;
	margin: auto;
	animation: GFG 5s infinite linear;
	
		}

		.ggoodd2{
			object-fit: cover !important;
			width: 100% !important;
			height: 100% !important;
			
			
		}

		.ggoodd{
			object-fit: cover !important;
			width: 100% !important;
			height: 100% !important;
			
			
		}

		.text-center{
			color: white;
			font-weight: 500px;
			/* 	 */
		}


		.waterr{
			position: absolute;
	top: 0;
	left: 370px !important;
	width: 50% !important;
	

	height: 100vh !important;
	object-fit: cover;
	opacity: 0.8;
	z-index: 1;
		}
.falling-pellet23{
	position: absolute;
	/* right: 0; */
	top: 0px !important;
	left: 380px !important;
	height: 100vh!important;
	width:50% !important;
		
			
		 
		}.imgg{
			height: 130% !important  ;
			width: 50% !important ;
			position: absolute;
			left: 380px;
			top: 10px !important;
			object-fit: cover !important;
			z-index: 1;
			padding-bottom: 400px !important;
			/* align-items: center !important;
			text-align: center; */


			
		
			
		  }
		  .god{
			position: absolute;
			position: fixed;
			top: 0px;
			left: 0px;
			height: 150% !important;
			width: 100%;
		}

		  .gim{
			position:absolute;
			top: -50px !important;
		  }
		  
		  .Agarpathy{
			position: absolute;
			bottom: 200px;
			right: 100px !important;
			height: 200px !important;
		  }
		  .fume2{
			position: absolute;
			left: 420px !important;
			top: 150px;
		}
		  .Agarpathy2{
			position: absolute;
			
			
			left: 400px !important;
			height: 200px !important;
			top: 370px;
		  }
		  
		  .imggParvathy{
			height: 100% !important;
			width: 50% !important;
			position: absolute;
			left: 380px;
			top: 10px;
			
			z-index: 1;

		  }
		  .vishnu{
			height:100%!important;
			width: 50% !important;
			position: absolute;
			left: 380px;
			top: 10px;
		  }
		  
		  .header-slide-con{
			width:100%;
			background-color:#ff6600;
			position:fixed;
			
			left:0px;
			position: absolute;
			top: -215px !important;;
			z-index:990;
			text-align:center;
			min-height:45px;
			padding-top:8px;
			margin-top:-40px;
				-webkit-transition: all 0.3s;
			-moz-transition: all 0.3s;
			-o-transition: all 0.3s;
			transition: all 0.3s;
			height: 17rem !important;
			/* opacity: 0.8; */
		}
		  .bg-img{
			width: 100%;
			position: absolute;
			position: fixed;
		

		  }
		  

.viewtopnav{
	margin-top:200px !important ;
}
		
		
	}
	@media screen and (min-width:1500px) {
		.thali{
			position: absolute;
			top: -500px;
			left: 700px;
			width: 1000px;
			width: 150px;
			height: 150px;
			border-radius: 50%;
			margin: auto;
			animation: GFG 5s infinite linear;
			
	
		}
		.ThaliContainer{
			position: absolute;
			position: fixed;
		  width: 50px;
		  height: 1000px;
		  z-index: 3;
		  
		  animation: moveInCircle 4s linear infinite;
		}
		
		@keyframes GFG {
			0% {
			  transform: rotate(0deg) translateY(100px) rotate(0deg);
			}
		  
			100% {
			  transform: rotate(360deg) translateY(100px) rotate(-360deg);
			}
		  }
		  .falling-pellet{
			position: absolute;
			top: 20px;
			left: 900px;
			position: fixed;
			width: 300px;
			height: 500px;
		 
		}

		.water{
			position:absolute !important ;
			left:700px !important;
		}
		.Kudams{ 
			position: absolute;
			left: 560px !important;
			width: 200px !important;
			top: -35px !important;
		}
		/* .god{
			background-image: url(../img/pxfuel.jpg) !important;
			background-size: cover;
			background-repeat: no-repeat;
			z-index: 1;
		} */
		
	}
	






.ThaliContainer{
	position: absolute;
	position: fixed;
  width: 50px;
  height: 200px;
  z-index: 3;
  
  animation: moveInCircle 4s linear infinite;
}
@keyframes GFG {
	0% {
	  transform: rotate(0deg) translateY(100px) rotate(0deg);
	}
  
	100% {
	  transform: rotate(360deg) translateY(100px) rotate(-360deg);
	}
  }
  /* .falling-pellet {
	animation: fallingAndShaking 5s ease infinite;
  } */
  
  /* .falling-pellet {
	animation: falling 5s linear infinite;
  }
  
  @keyframes falling {
	0% {
	  transform: translateY(-100px);
	}
	100% {
	  transform: translateY(calc(100vh + 100px));
	}
  }
  
  
.Pellet{
	position: absolute;
	top: 10px;
}  

.Pellet2{
	position: absolute;
	position: 10;
}  
  
.Pellet3{
	position: absolute;
	top: 0px;
}   */
.falling-pellet{
	position: absolute;
	top: 20px;
	position: fixed;
	width: 200px;
	height: 500px;
 
}
.text-center{
	color: white;
	font-weight: 500px;
}
/* .Pellet{
	width: 100% !important;
	position: absolute;
	left: 100px;
	
	top:0;

	z-index: 1
} */

.falling-pellet2{
	position: absolute;
	bottom: 150px;
}
.falling-pellet3{
	position: absolute;
	top: 20px;
	left: 100px;
}
.falling-pellet4{
	position: absolute;
	bottom: 150px;
	right: 0;
}
.ganesh {
	position: absolute;
	top: 0; 
	left: 0; 
	width: 100%; 
	height: auto; 
	z-index: 999;
	bottom: 300px;
  }

 
  .bell {
	position: absolute;
	top: 20px;
	z-index: 1;
	border: none;
	background: none;
	padding: 0;
	right: 50px;
	font-size: inherit;
	color: inherit;
	cursor: pointer;
	animation: btn3 1s ease-in-out infinite; /* Increase duration to slow down */
	/* background-color: #950000; */
  }
  .btn3 {
	border: none; /* Remove button border */
	padding: 0; /* Remove padding */
	background: none; /* Remove background color */
	cursor: pointer;
	position: absolute;
	top: 20px;
	right: -40px;
  
  }
  .audio{
	position: absolute;
	top: 500px;
	visibility: hidden;
  }
  

  .Agarpathy{
	position: absolute;
	bottom: 200px;
	right: 10px;
	height: 200px !important;
  }
  .Agarpathy2{
	position: absolute;
	
	
	left: -50px ;
	height: 200px ;
	top: 270px;
  }

  .sound3{
	position: absolute;
	top: 0;
	left: -10px;
  }
  .imgg{
	height: 100vh  ;
	width: 100% ;
	object-fit: cover !important;
	position: absolute;
	top: 0;
	

	
  }

  .sound3{
	border: none; /* Remove button border */
	padding: 0; /* Remove padding */
	background: none; /* Remove background color */
	cursor: pointer;
	position: absolute;
	top: 40px;
	left: 15px;
	height: 30px;
	width: 30px;

  }

  .MuteMusic{
	border: none; /* Remove button border */
	padding: 0; /* Remove padding */
	background: none; /* Remove background color */
	cursor: pointer;
	position: absolute;
	top: 95px;
	left: 15px;
	height: 30px;
	width: 30px;
  }

  /* @media screen and (max-width: 1600px) {
    .god {
        position: absolute;
        left: 460px;
		width: 600px !important;
    }
	.Agarpathy{
		position: absolute;
		right: 500px;
	}
	.falling-pellet{
		position: absolute;
		left: 500px;
		
	}
	.thali{
		position: absolute;
		right: 10re;
		top: -500px;

	}
	
}

	
   */
   .fume{
	position: absolute;
	right: 60px;
	top: 100px;

   }
   @keyframes flow {
	0% {
	  top: 0;
	}
	50% {
	  top: 10px;
	}
	100% {
	  top: 0;
	}
  }
  
  .Kudams {
	position: absolute;
	top: 0;
	left: 100px;
	animation: flow 2s linear infinite;
	z-index: 1; /* Adjust the duration and timing function as needed */
  }
  
   @keyframes flow {
	0% {
	  transform: translateY(0);
	}
	50% {
	  transform: translateY(5px);
	}
	100% {
	  transform: translateY(0);
	}
  }
  
  .water {
	position: absolute;
	top: 35px;
	left: 150px;
	height: 100px !important;
	width: 100px !important;
	animation: flow 2s linear infinite; 
	/* z-index: 1; */
	/* Adjust the duration and timing function as needed */
  }
  
   .bookseva{
	position: absolute;
	position: fixed;
	top: 350px ;
	left: 20px;
	opacity: 0.8;
	
   }

   .falling-pellet23{
	position: absolute;
	/* right: 0; */
	top: 0px ;
	left: 50px;
	height: 100vh;
	width:100% ;
	/* width: 100%; */
	/* object-fit: cover;
	justify-content: center; */
	/* opacity: 0.8; */
   }
  .imgBell{
	position: absolute;
	top: 40px !important;
	right: 10px;
	position: fixed;
	height: 30px !important;
	width: 30px !important;
	/* background-color:#950000; */
	border-radius: 5px;
	padding-left: 2px;
  }
  nav{
	background-color: #000000;
	z-index: -1;
  }



  @media screen and (max-width:410px){

	.thali{
		width: 100px;
			height: 100px;
			border-radius: 50%;
			margin: auto;
			animation: GFG 5s infinite linear;
			position: absolute;
			
		
			top: -500px;
			left: 150px;
			/* justify-content: center;
			align-items: center; */
	
  }
}
/* @media screen and (max-width:390px) {

	.fume{
	position: absolute;
	right: 60px;
	top: 400px;

   }
} */


  .bellring {
	position: fixed;
	right: 10px;
	position: fixed;
	height: 30px !important;
	width: 30px !important;
	transform-origin: center top;
	animation: bellRing 1s ease-in-out infinite;
  }
  
  @keyframes bellRing {
	0%, 100% {
	  transform: rotate(0deg);
	}
	50% {
	  transform: rotate(-15deg); /* Negative rotation for swinging upwards */
	}
  }
  
  /* Apply the bell ringing animation to the bell icon */
  /* .bellring img {
	animation: bellRing 2s ease infinite;
	height: 100%;
	width: 100%;
  } */
  
  .waterr {
	position: absolute;
	top: 0;
	left: 50px;
	width: 100%;
	height: 100vh !important;
	object-fit: cover;
	opacity: 0.8;
	z-index: 1;
  }

  .loader {
	border: 6px solid #f3f3f3;
	border-top: 6px solid #3498db;
	border-radius: 50%;
	position: absolute;
	top: 0;
	z-index: 1;
	left: 50px;
	width: 50px;
	height: 50px;
	animation: spin 2s linear infinite;
}

@keyframes spin {
	0% { transform: rotate(0deg); }
	100% { transform: rotate(360deg); }
}


  .kudam {
	position: relative;
	width: 100%;
	height: 100vh;
	overflow: hidden;
	/* Add any additional styles or modifications you need for the .kudam container here */
  }
  
  .waterrr {
	position: absolute;
	top: 0;
	left: 50px;
	width: 100%;
	height: 300vh !important;
	object-fit: cover;
	opacity: 0.8;
	z-index: 1;
	overflow: hidden;
	animation: water-flow 4s linear infinite;
  }
  
  .waterrr:nth-child(2) {
	animation-delay: 0s; /* Start immediately after the first water element */
  }
  
  @keyframes water-flow {
	0% {
	  transform: translateY(-100%);
	}
	100% {
	  transform: translateY(100%);
	}
  }
  
  
  
  /* .KudamContainer{
	position: relative;
	width: 100%;
	height: 100vh;
	overflow: hidden;
	top: 0;
  
  } */
  
  
	/* .waterr2{
		position: absolute;
		top: 0;
		object-fit: cover;
		height: 100vh;
		opacity: 0.8;
		

	} */
	.fume2{
		position: absolute;
		left: 0;
		top: 150px;
	}


/* CSS for the arrow */
.arrow-container {
	position: relative;
	top: 0;
  }
  
element.style {
}
<style>
.download-app-container-mob[_ngcontent-vva-c110] .down-btn[_ngcontent-vva-c110], .download-app-container[_ngcontent-vva-c110] .down-btn[_ngcontent-vva-c110] {
    bottom: -16px;
}
.download-app-container .down-btn {
    bottom: -16px;
}
.down-btn {
    height: 28px;
    width: 60px;
    z-index: 999;
    left: 50%;
    margin-left: -30px;
    border: none;
    float: left;
    position: absolute;
    bottom: -27px;
    background: url(https://bookseva.com/assets/img/top-menu-bg.svg) top no-repeat;
    padding: 0px;
    outline: none !important;
    cursor: pointer;
}
  .arrow-down-btn {
	position: absolute;
	top: -650px !important;
	right: 150px; /* Adjust the right position as needed */
	background-color: violet;
	border: none;
	cursor: pointer;
	font-size: 24px;
	color: #fff; /* Arrow color - change this to a visible color */
	transition: transform 0.3s;
	z-index: 100;
  }
  
  /* Rotate the arrow when the new div is shown */
  .rotate180 {
	transform: rotate(180deg);
  }
  
  /* CSS for the new div */
  .new-div {
	background-color: orange; /* Background color of the new div */
	padding: 20px;
	margin-top: 20px;
	box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
	transition: margin-top 0.3s;
	position: absolute;
	top: 20px;
	z-index: 100;
	border-bottom-left-radius: 50% 50px; /* Half-circle effect at the bottom */
	border-bottom-right-radius: 50% 50px; /* Half-circle effect at the bottom */
  }
  
  
  
  /* Animate the appearance of the new div */
  .new-div.show {
	margin-top: 0;
  }
  
  

  .justify-center{
	justify-content: center !important;
}


.header-slide-con{
	width:100%;
	background-color:#ff6600;
	position:fixed;
	
	left:0px;
	top: -160px ;
	z-index:990;
	text-align:center;
	min-height:45px;
	padding-top:8px;
	margin-top:-40px;
	    -webkit-transition: all 0.3s;
    -moz-transition: all 0.3s;
    -o-transition: all 0.3s;
    transition: all 0.3s;
	height: 13rem;
	opacity: 0.9;
}

.img-fluid {
	max-width: 100% !important;
	/* height: auto !important; */
	z-index: 1;
	position: absolute;
	top: 0;
  }

  
  

.header-slide-con .col-4 button{
	width:100%;
	text-align:center;
	border:none;
	outline:none;
	background:none;
	color:#860000;
	font-weight:500;
	font-size:14px;
	cursor:pointer;
	min-height:26px;
}

.header-slide-con .col-4 button i{
	margin-right:5px;
}

.header-slide-con .col-4:nth-child(2){
	position:relative;
}

.header-slide-con .col-4:nth-child(2):before{
	width:1px;
	height:26px;
	background-color:#da4400;
	position:absolute;
	left:0px;
	top:0px;
	content:'';
}	



.header-slide-con .col-4:nth-child(2):after{
	width:1px;
	height:26px;
	background-color:#da4400;
	position:absolute;
	right:0px;
	top:0px;
	content:'';
}	

.header-slide-con .col-md-5{
	position:relative;
}



.download-app-container .row .col-6 button{
	padding:0px;
	margin:0px 0px 10px 0px;
	border:none;
	outline:none;
	float:right;
	cursor:pointer;
}

.download-app-container-mob .row .col-6 button{
	padding:0px;
	margin:0px 0px 10px 0px;
	border:none;
	outline:none;
	float:right;
	cursor:pointer;
}

.download-app-container .row .col-6:nth-child(2) button{
	float:left;
}

.download-app-container-mob .row .col-6:nth-child(2) button{
	float:left;
}


.download-app-container .header-search input{
	height:70px;
}
.download-app-container-mob .header-search input{
	height:70px;
}

.download-app-container{
	margin-top:-98px;
}
.download-app-container-mob{
    margin-top: -42px;
}

.download-app-container h6{
	padding:0px;
	margin:0px 0px 5px 0px;
	color: #860000;
    font-weight: 500;
    font-size: 14px;
}
.download-app-container-mob h6{
	padding:0px;
	margin:0px 0px 5px 0px;
	color: #860000;
    font-weight: 500;
    font-size: 14px;
}

.download-app-container .bell-margintop{
	top:120px !important;
}
.download-app-container-mob .bell-margintop{
	top:120px !important;
}

.download-app-container .down-btn{
	bottom: -16px;
}
.download-app-container-mob .down-btn{
	bottom: -16px;
}

.viewtopnav{
	margin-top:146px ;
}

.header-search{
	width:0%;
	top:0px;
	left:0px;
	position:absolute;
	z-index:995;
	float:left;
	opacity:0;
	-webkit-transition: all 0.3s;
    -moz-transition: all 0.3s;
    -o-transition: all 0.3s;
    transition: all 0.3s;
}


.header-search button{
	position:absolute;
	top:0px;
	right:20px;
	z-index:998;
	float:right;
	width:auto;
	font-size:18px;
	background:none;
	color:#860000;
	font-weight:500;
	cursor:pointer;
	outline:none !important;
	border:none;
	outline:none;
}

.header-search input{
	width:100%;
	height:45px;
	float:left;
	border:none;
	background-color:#ff6600;
	outline:none;
	padding-left:20px;
	color:#8e0000;
	font-size:15px;
	margin-top:-8px;
}

.header-search input::-webkit-input-placeholder { /* Chrome/Opera/Safari */
	color:#ca3600;
}
.header-search input::-moz-placeholder { /* Firefox 19+ */
	color:#ca3600;
}
.header-search input:-ms-input-placeholder { /* IE 10+ */
	color:#ca3600;
}
.header-search input:-moz-placeholder { /* Firefox 18- */
	color:#ca3600;
}


.notification_container{
	position:absolute;
	top:45px;
	right:40px;
	z-index:990;
	cursor:pointer;
	
	-webkit-transition: all 0.3s;
    -moz-transition: all 0.3s;
    -o-transition: all 0.3s;
    transition: all 0.3s;
}

.notification_container i{
	color:#fe660a;
	font-size:18px;
	text-shadow: 0px 3px 3px rgba(0, 0, 0, 0.2); 
}
#bell-notification i{
	color:#fe660a;
	font-size:32px;
	text-shadow: 0px 3px 3px rgba(0, 0, 0, 0.2); 
}

.bell-margintop{
	top:105px;
}

.notification_container font{
	position:absolute;
	top:-6px;
	right:-6px;
	z-index:995;
	min-width: 20px;
    height: 20px;
    background-color: #ffe0cd;
    -moz-border-radius: 50%;
    border-radius: 50%;
    text-align: center;
    font-size: 10px;
    font-weight: 700;
    line-height: 22px;
    color: #860000;
    -webkit-box-shadow: 0px 0px 8px 0px rgba(0,0,0,0.5);
    -moz-box-shadow: 0px 0px 8px 0px rgba(0,0,0,0.5);
    box-shadow: 0px 0px 8px 0px rgba(0,0,0,0.5);
}
/* @media screen and (width:414px){
	.thali{
		position: absolute;
		top:;
	}
} */

@media screen and (width:540px){
	.thali{
		position: absolute;
		top: -500px;
		left: 150px ;
		width: 1000px;
		width: 150px;
		height: 150px;
		border-radius: 50%;
		margin: auto;
		animation: GFG 5s infinite linear;
		
	
	}
	.imgg{
		height: 150% !important  ;
		width: 100% ;
		object-fit: cover !important;
		position: absolute;
		top: 0px;
		
	
		
	  }
	.viewtopnav{
		margin-top:246px !important ;
	}

	
	.header-slide-con{
		width:129%;
		background-color:#ff6600;
		position:fixed;
		
		left:0px;
		position: absolute;
		top: -250px !important;;
		z-index:990;
		text-align:center;
		min-height:45px;
		padding-top:8px;
		margin-top:-40px;
			-webkit-transition: all 0.3s;
		-moz-transition: all 0.3s;
		-o-transition: all 0.3s;
		transition: all 0.3s;
		height: 18.8rem !important;
		/* opacity: 0.8; */
	}
	.vishnu{
	
		height: 100vh !important;
		width: 100%;
		object-fit: cover;
		/* justify-content: center; */
		/* position: absolute;
		right: 50px;
	 */
		
	  

}
	.falling-pellet23{
		position: absolute;
		/* right: 0; */
		top: 0px !important;
		left: 50px !important;
		height: 100vh!important;
		width:100% !important;
	}



	.waterr{
		position: absolute;
top: 0;
left: 50px !important;
/* right: 700px !important; */
width: 100% !important;


height: 100vh !important;
object-fit: cover;
opacity: 0.8;
z-index: 1;
	}

}
@media screen and (min-width: 768px){
	.vishnu{
	
		height: 100vh ;
		width: 100%;
		object-fit: cover;
		/* justify-content: center; */
		/* position: absolute;
		right: 50px;
	 */
		
	  

}
.thali{
	position: absolute;
	top: -700px;
	left: 300px ;
	width: 1000px;
	width: 150px;
	height: 150px;
	border-radius: 50%;
	margin: auto;
	animation: GFG 5s infinite linear;
	

}


}

@media screen and (min-width: 412px) {
	.thali{
		position: absolute;
		top: -600px !important;
	}
   
    .bell-margintop{
		top:105px !important;
	}

	.imgg{
		height: 100vh  ;
		width: 100% ;
		object-fit: cover !important;
		position: absolute;
		top: 0;
		
	
		
	  }
    /* 	
	code added by jikhil on 29-10-2020 */
    .notification_container {
        right: 20px !important;
    }
    
   
}

.btnnn2{
	position: absolute;
	top: 500px !important;
	background-color: black;
	z-index: 1;
}

.ggoodd{
	object-fit: cover;
	width: 100%;
	height: 90%;
	padding-top: 4px;
	border-radius: 50%;

	
}
.ggoodd4{
	object-fit: cover;
	width: 100%;
	height: 100%;
	padding-top: 4px;
	border-radius: 50%;

}
.ggoodd1{
	object-fit: cover;
	width: 100%;
	height: 100%;
	padding-top: 10px;
	border-radius: 50%;

}
.imgBook{
	
	justify-content: center;
	width: 50%;
    /* height: 50px; */
	z-index: 1;
	opacity: 1 !important;
	
}
.immgBook{
	background-color: #fe660a;
	opacity: 1 !important;
	position: absolute;
	top: 10px !important; 
	 right: 0px;
	width: 100%;



	z-index: 1;
}
.card{
	/* background-color: #fe660a; */
	background-color: #FFE0CC;
	
	border-radius: 50%;
	opacity: 0.5px;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	overflow: hidden; 
	
}


.ggoodd2{
	object-fit: cover;
	width: 100%;
	height: 100%;
	padding-top: 10px;
	border-radius: 50%;
	/* word-wrap: break-word; */

}


.textC{
	
	color: #ffe0cc;
	font-family: 'Roboto', sans-serif; /* 'Roboto' as the primary font and 'sans-serif' as a fallback */
    font-size: 13px; /* You can specify font-size separately if needed */
	/* font-family: 13px Roboto,sans-serif !important; */
	font-weight: 500px;
}
.textCG {
    position: relative;
    top: -8px;
    color: #ffe0cc;
    font-family: 'Roboto', sans-serif; /* 'Roboto' as the primary font and 'sans-serif' as a fallback */
    font-size: 13px; /* You can specify font-size separately if needed */
	font-weight: 500px !important; 
}

.textCv{
	position: relative;
	top: 0px;
	color: #ffe0cc;
	font-family: 'Roboto', sans-serif; /* 'Roboto' as the primary font and 'sans-serif' as a fallback */
    font-size: 13px; /* You can specify font-size separately if needed */
	font-weight: 500px;


}
.textCp{
	position: relative;
	top: 0px;
	color: #ffe0cc;
	font-family: 'Roboto', sans-serif; /* 'Roboto' as the primary font and 'sans-serif' as a fallback */
    font-size: 13px; /* You can specify font-size separately if needed */
}
.imggParvathy{
	
		height: 100vh !important;
		width: 100%;
		object-fit: cover;
		/* justify-content: center; */
		/* position: absolute;
		right: 50px;
	 */
		
	  

}
/* .ggoodd1{
	object-fit: cover;
	width: 100%;
	height: 100%;
	padding-top: 10px;
	border-radius: 50%;

} */


.Mimg{
	/* background-color: black; */
	border-radius: 10px;
	position: relative;
	left: 0;
	/* position: fixed; */

}


.shareButton{
	border:none;
	outline:none;
	background:none;
}
.share{
	position: absolute;
    right: 10px;
    top: 95px;
    height: 30px;
    width: 30px;
	position: fixed;
	z-index: 1;
}

/* 
.shareCard{
	position: absolute;
	top: 100px;
	z-index: 1;
	/* height: 10px !important;
	width: 10px !important; */
/* Custom show box styling */
.custom-show-box {
	position: absolute;
	top: 248px;
	right: 20px;
	transform: translate(-50%, -50%);
	/* background-color: #fff; */
	/* border: 1px solid #ccc; */
	/* box-shadow: 0 0 10px rgba(0, 0, 0, 0.2); */
	padding: 20px;
	/* max-width: 300px; */
	width: 5px;
	position: fixed;
	
  }
  
  .custom-show-box-content {
	text-align: center;
  }
  
  .social-icon {
	width: 32px;
	height: 32px;
	margin-bottom: 5px;
  }
  
  /* Media queries for responsiveness */
  @media (max-width: 768px) {
	.custom-show-box {
	  max-width: 80%;
	}
  }
  /* .icon-btn {
	transition: transform 0.2s ease-in-out, box-shadow 0.2s ease-in-out;
  } */
  
  /* .icon-btn:hover {
	transform: scale(1.1); 
	box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2); 
  } */
  /* .social-icon {
	width: 40px;
	height: 40px;
	z-index: 1; 
	position: absolute;
	top: 150px;
  }	 */
  /* Add this CSS to your stylesheet */

  .wp{
	height: 50px;
	width: 50px;
    position: relative;
	top: 10px;
	/* padding-right: 10px; */
	right: 7px;
  }

  .test{
	opacity: 1;
  }