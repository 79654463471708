.heading{
    width: 100%;
    height: 0px   ;
    z-index: 1;
    padding-left: 100px;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 0px;
    right: 0px;
    



}
.butttton{
    border: none;
    background-color: transparent;
    cursor: pointer;
    position: absolute;
    top: 15px;
    height: 100px;
   /* background-color: #fe660a; */
   border-radius: 10px;
   height: 60px;
   opacity: 0.9;
   text-align: center;
  
    
    
}
.iii{
    height: 60px;
    
    
    
}
@media screen and (min-width:1000px){
    .heading{
       
        
        z-index: 1;
        position: relative;
        right: 153px;
        
      
      
        
       
       
        text-align: center !important;
    
    
    
    }
    .iii{
        height: 60px;
        text-align: center;
        z-index: 1;
        
        
        
    }
    
}

@media screen and (min-width: 650px) {
    .heading{
       
        
        z-index: 1;
        position: relative;
        right: 153px;
        
      
      
        
       
       
        text-align: center !important;
    
    
    
    }

}
@media screen and (width: 540px){
   
  
    .heading{
       
        
        z-index: 1;
        position: relative;
        right: 85px;
        
      
      
        
       
       
        text-align: center !important;
    
    
    
    }



}
